export class Paragraph {
  _editor
  name

  constructor (editor) {
    this._editor = editor
    this.name = 'p'
  }

  insert ({ content } = { content: '' }) {
    const el = this._editor._factory.createElement('p', content, {}, { whiteSpace: true })
    this._editor.insert(el)
    return el
  }
}
