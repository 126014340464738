export class H {
  _editor
  name

  constructor (editor) {
    this._editor = editor
    this.name = 'h'
  }

  insert ({ level, content }) {
    const el = this._editor._factory.createElement(`h${level || ''}`, content, {}, { whiteSpace: true })
    this._editor.insert(el, { direct: true })
    return el
  }
}
