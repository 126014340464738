import { Paragraph } from './paragraph'
import { H } from './h'
import { List } from './list'
import { Table } from './table'
import { HorizontalRule } from './horizontal-rule'
import { HardBreak } from './hard-break'
import { Transform } from './transform'
import { Pre } from './pre'
import { Span } from './span'

export default [
  Paragraph,
  H,
  List,
  Table,
  HorizontalRule,
  HardBreak,
  Transform,
  Pre,
  Span
]