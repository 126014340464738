export class List {
  _editor
  name

  constructor (editor) {
    this._editor = editor
    this.name = 'list'
  }

  insert ({ type, rows = 3 } = { type: 'ul', rows: 3 }) {
    const el = this._editor._factory.createElement(type)

    new Array(rows).fill('li').forEach(tag => {
      const row = this._editor._factory.createElement(tag)
      el.appendChild(row)
    })

    this._editor.insert(el, { direct: true })
    return el
  }
}
