import plugins from './plugins'

class Factory {
  createElement (tag, content = '', props = {}, options = {}) {
    const el = document.createElement(tag)

    el.textContent = content || ''

    if (!el.textContent && options.whiteSpace) {
      el.innerHTML = '&nbsp;'
    }

    Object.entries(props).forEach(([key, value]) => {
      el.setAttribute(key, value)
    })

    return el
  }

  createShadow (el, mode = 'closed') {
    return el.attachShadow({ mode })
  }
}

export class Editor {
  _config
  _document
  _container
  _shadow
  _factory

  plugins
  lastFocusedElement

  get isFocused () {
    return !!this.lastFocusedElement
  }

  static create (config) {
    config.plugins = [
      ...plugins,
      ...(config.plugins || [])
    ]

    return new Editor(document, new Factory(), config)
  }

  constructor (document, factory, config) {
    this._config = config
    this._document = document
    this._factory = factory
    this.plugins = {}

    const verifyMessage = this._verifyContent(this._config.content || '')

    if (verifyMessage) {
      this.submitError(verifyMessage)
      return
    }

    this.init()
  }

  getStyles ({ element = this.lastFocusedElement, type = 'string' } = { element: this.lastFocusedElement, type: 'string' }) {
    return type === 'string'
      ? element.getAttribute('style')
      : element.style
  }

  replaceStyle ({ element = this.lastFocusedElement, styles } = { element: this.lastFocusedElement }) {
    if (styles && typeof styles === 'object') {
      element.style = ''

      Object.entries(styles).forEach(([key, value]) => {
        element.style[key] = value
      })
    } else {
      element.style = styles || ''
    }

    this._submitUpdate()
    return element
  }

  updateStyle ({ element = this.lastFocusedElement, styles } = { element: this.lastFocusedElement }) {
    if (styles && typeof styles === 'object') {
      Object.entries(styles).forEach(([key, value]) => {
        element.style[key] = value
      })
    } else {
      element.style = (element.getAttribute('style') || '') + (styles || '')
    }

    this._submitUpdate()
    return element
  }

  init () {
    const { container, shadow } = this._initShadowDom()

    this._container = container
    this._shadow = shadow
    this.applyPlugins(this._config.plugins || [])

    this._handleMouse = this._handleMouse.bind(this)
    this._handleKeydown = this._handleKeydown.bind(this)
    this._handleFocus = this._handleFocus.bind(this)
    this._handleBlur = this._handleBlur.bind(this)

    this._container.addEventListener('mousedown', this._handleMouse)
    this._container.addEventListener('keydown', this._handleKeydown)
    this._container.addEventListener('focus', this._handleFocus)
    this._container.addEventListener('blur', this._handleBlur)
  }

  _verifyContent (content) {
    if (content.includes('<script')) {
      return 'Content includes script tag. For security reasons we can\'t render it.'
    }

    return ''
  }

  submitError (error) {
    return typeof this._config.onError === 'function' && this._config.onError(error)
  }

  getElementIndex (element) {
    return Array.prototype.slice.call(element.parentNode.children).indexOf(element)
  }

  setLastFocusedElement () {
    this.lastFocusedElement = this.getLastFocusedElement()
  }

  getLastFocusedElement () {
    if (this._config.focus === 'container') {
      return this._container
    }

    if (!this._shadow) {
      return this._container
    }

    const el = this._shadow.getSelection().anchorNode || this._container

    return el && el.nodeType && el.nodeType === 3
      ? el.parentNode
      : el
  }

  applyPlugins (plugins) {
    this.plugins = plugins.reduce((acc, Plugin) => {
      const plugin = new Plugin(this)
      const name = plugin.name || Plugin.name.toLowerCase()
      
      acc[name] = plugin
      
      return acc
    }, {})
  }

  destroy () {
    this._container.removeEventListener('keydown', this._handleMouse)
    this._container.removeEventListener('keydown', this._handleKeydown)
    this._container.removeEventListener('focus', this._handleFocus)
    this._container.removeEventListener('blur', this._handleBlur)
    return this
  }

  insert (el, options = {}) {
    if (options.direct) {
      this.lastFocusedElement.appendChild(el)
      this._submitUpdate()
      return el
    }

    if (this.lastFocusedElement === this._container) {
      this._container.appendChild(el)
      this._submitUpdate()
      return el
    }

    if (!this.lastFocusedElement) {
      this._container.appendChild(el)
    } else {
      const parent = this.lastFocusedElement.parentNode
  
      if (parent === this.container || this.lastFocusedElement.nodeType === 3) {
        parent.appendChild(el)
      } else {
        parent.insertBefore(el, this.lastFocusedElement.nextSibling)
      }
    }

    this._submitUpdate()
    return el
  }

  insertAt (parent, child, at) {
    parent.insertBefore(child, parent.children[at + 1])
    this._submitUpdate()
    return parent
  }

  removeAt (parent, at) {
    this.remove(parent.children[at])
    this._submitUpdate()
    return null
  }

  remove (element) {
    element.remove()
    this._submitUpdate()
    return null
  }

  _handleMouse () {
    setTimeout(() => {
      this.setLastFocusedElement()
    })
  }

  findParentElement (element, tag) {
    if (!element) {
      return null
    }

    if (element.tagName === tag) {
      return element
    }

    if (element === this._container) {
      return null
    }

    return this.findParentElement(element.parentNode, tag)
  }

  _initShadowDom () {
    const shadow = this._factory.createShadow(this._config.parent)
    const container = this._factory.createElement('div', '', { class: 'ProseMirror', style: 'outline: none;' })

    shadow.appendChild(container)

    container.innerHTML = this._config.content
    container.setAttribute('contenteditable', true)
    container.style.whiteSpace = 'wrap'

    return { shadow, container }
  }

  _createElement (tag, content = '', props = {}, options = {}) {
    const el = this._document.createElement(tag)

    el.textContent = content || ''

    if (!el.textContent && options.whiteSpace) {
      el.innerHTML = '&nbsp;'
    }

    Object.entries(props).forEach(([key, value]) => {
      el.setAttribute(key, value)
    })

    return el
  }

  _handleFocus (e) {
    return typeof this._config.onFocus === 'function' && this._config.onFocus(e)
  }

  _handleBlur (e) {
    return typeof this._config.onBlur === 'function' && this._config.onBlur(e)
  }

  _submitUpdate () {
    this.setLastFocusedElement()
    return typeof this._config.onUpdate === 'function' && this._config.onUpdate(this._container.innerHTML)
  }

  _handleKeydown (e) {
    if (this.lastFocusedElement !== this._container && e && e.key === 'Backspace' && !this.lastFocusedElement.textContent) {
      this.lastFocusedElement.parentNode.focus()
      this.lastFocusedElement.remove()
    }

    setTimeout(() => {
      this._submitUpdate()
    })

    return this
  }
  
  setContent (content) {
    const verifyMessage = this._verifyContent(this._config.content || '')

    if (verifyMessage) {
      this.submitError(verifyMessage)
      return
    }

    this.destroy()
    this._config.parent.innerHTML = ''
    this._config.content = content
    this.init()
  }

  getHTML () {
    return this._container.innerHTML
  }
}
