export class Transform {
  _editor
  name

  constructor (editor) {
    this._editor = editor
    this.name = 'transform'
  }

  bold (element) {
    if (element.style.fontWeight === 'bold') {
      element.style.fontWeight = 'normal'
      return element
    }

    element.style.fontWeight = 'bold'
    return element
  }

  italic (element) {
    if (element.style.fontStyle === 'italic') {
      element.style.fontStyle = 'normal'
      return element
    }

    element.style.fontStyle = 'italic'

    return element
  }

  strike (element) {
    if (element.style.textDecoration === 'line-through') {
      element.style.textDecoration = 'none'
      return element
    }

    element.style.textDecoration = 'line-through'

    return element
  }

  highlight (element) {
    if (element.style.backgroundColor === 'rgb(255, 255, 0)') {
      element.style.backgroundColor = ''
      return element
    }

    element.style.backgroundColor = 'rgb(255, 255, 0)'

    return element
  }

  toggle ({ element = this._editor.lastFocusedElement, style } = { element: this._editor.lastFocusedElement }) {
    typeof this[style] === 'function' && this[style](element)
    this._editor._submitUpdate()
    return element
  }

  to ({ element = this._editor.lastFocusedElement, tag } = { element: this._editor.lastFocusedElement }) {
    const newElement = this._editor._factory.createElement(tag, '')
    newElement.innerHTML = element.innerHTML || '&nbsp;'
    element.replaceWith(newElement)
    this._editor._submitUpdate()
    return newElement
  }

  style (styles = {}, element = this._editor.lastFocusedElement) {
    Object.entries(styles).forEach(([key, value]) => {
      element.style[key] = value
    })

    return element
  }
}
