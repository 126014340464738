export class HardBreak {
  _editor
  name

  constructor (editor) {
    this._editor = editor
    this.name = 'br'
  }

  insert () {
    const el = this._editor._factory.createElement('br')
    this._editor.insert(el)
    return el
  }
}
