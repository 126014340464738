export class Span {
  _editor
  name

  constructor (editor) {
    this._editor = editor
    this.name = 'span'
  }

  insert ({ content, props }) {
    const el = this._editor._factory.createElement('span', content, props, { whiteSpace: true })
    
    this._editor.insert(el)

    if (content) {
      const span = this._editor._factory.createElement('span', '', {}, { whiteSpace: true })
      this._editor.insert(span)
      span.focus()
    }

    return el
  }
}
