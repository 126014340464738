export class Table {
  _editor
  name
  can

  constructor (editor) {
    this._editor = editor
    this.name = 'table'
    this.can = Object.getOwnPropertyNames(Table.prototype).filter(x => x !== 'constructor').reduce((acc, key) => {
      acc[key] = () => {
        return !!this._editor.findParentElement(this._editor.lastFocusedElement, 'TABLE')
      }
      
      return acc
    }, {})
  }

  _setDefaultTDStyle (element) {
    element.style.border = '1px solid #000'
    element.style.resize = 'both'
    element.style.overflow = 'auto'
    return element
  }

  insertColumn (index) {
    const table = this._editor.findParentElement(this._editor.lastFocusedElement, 'TABLE')
    const elements = table.getElementsByTagName('tr')

    for (let i = 0; i < elements.length; i++) {
      const td = this._editor._factory.createElement('td', '', {}, { whiteSpace: true })

      this._setDefaultTDStyle(td)
      
      this._editor.insertAt(elements[i], td, index)
    }
  }

  insertRow (index) {
    const table = this._editor.findParentElement(this._editor.lastFocusedElement, 'TABLE')
    const body = this._editor.findParentElement(this._editor.lastFocusedElement, 'TBODY')
    const tr = this._editor._factory.createElement('tr', '', {}, { whiteSpace: true })
    
    new Array(table.getElementsByTagName('tr')[0].children.length - 1).fill('td').forEach(tag => {
      const td = this._editor._factory.createElement(tag, '', {}, { whiteSpace: true })
      this._setDefaultTDStyle(td)
      tr.appendChild(td)
    })

    this._editor.insertAt(body, tr, index)
  }

  addColumnBefore () {
    const index = this._editor.getElementIndex(this._editor.findParentElement(this._editor.lastFocusedElement, 'TD'))
    this.insertColumn(index - 1)
    return true
  }

  addColumnAfter () {
    const index = this._editor.getElementIndex(this._editor.findParentElement(this._editor.lastFocusedElement, 'TD'))
    this.insertColumn(index + 1)
    return true
  }

  deleteColumn () {
    const index = this._editor.getElementIndex(this._editor.findParentElement(this._editor.lastFocusedElement, 'TD'))
    const table = this._editor.findParentElement(this._editor.lastFocusedElement, 'TABLE')
    
    const rows = table.getElementsByTagName('tr')

    for (let i = 0; i < rows.length; i++) {
      this._editor.remove(rows[i].children[index])
    }

    return true
  }

  addRowBefore () {
    const index = this._editor.getElementIndex(this._editor.findParentElement(this._editor.lastFocusedElement, 'TR'))
    this.insertRow(index - 1)
    return true
  }

  addRowAfter () {
    const index = this._editor.getElementIndex(this._editor.findParentElement(this._editor.lastFocusedElement, 'TR'))
    this.insertRow(index + 1)
    return true
  }

  deleteRow () {
    this._editor.remove(this._editor.findParentElement(this._editor.lastFocusedElement, 'TR'))
    return true
  }

  deleteTable () {
    const table = this._editor.findParentElement(this._editor.lastFocusedElement, 'TABLE')
    this._editor.remove(table)
    return true
  }
  
  insert({ rows, cols }) {
    const table = this.createTable()

    const thead = this._editor._factory.createElement('thead')
    const tbody = this._editor._factory.createElement('tbody')
    const elements = [thead, tbody]

    elements.forEach(el => {
      table.appendChild(el)
    })

    this.createHeader(thead, cols)
    this.createBody(rows, tbody, cols)
    this._editor.insert(table)

    return table
  }

  createBody(rows, tbody, cols) {
    new Array(rows).fill('tr').forEach(x => {
      const el = this._editor._factory.createElement(x)
      tbody.appendChild(el)

      new Array(cols).fill('td').forEach(x => {
        const e = this._editor._factory.createElement(x, '', {}, { whiteSpace: true })
        this._setDefaultTDStyle(e)
        el.appendChild(e)
      })
    })
  }

  createHeader(thead, cols) {
    const headerTr = this._editor._factory.createElement('tr')
    thead.appendChild(headerTr)

    new Array(cols).fill('td').forEach(x => {
      const el = this._editor._factory.createElement(x, '', {}, { whiteSpace: true })
      headerTr.appendChild(el)
      this._setDefaultTDStyle(el)
    })
  }

  createTable() {
    const table = this._editor._factory.createElement('table')
    table.style.border = '1px solid #000'
    table.style.width = '100%'
    table.style.borderCollapse = 'collapse'
    table.setAttribute('contenteditable', true)
    return table
  }
}
