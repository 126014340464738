export class HorizontalRule {
  _editor
  name

  constructor (editor) {
    this._editor = editor
    this.name = 'hr'
  }

  insert () {
    const el = this._editor._factory.createElement('hr')
    this._editor.insert(el)
    return el
  }
}
